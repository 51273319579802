<template>
  <div>
    <div v-if="!data_loaded">
      <v-skeleton-loader
        v-for="a in 5"
        :key="a"
        type="article"
      ></v-skeleton-loader>
    </div>

    <v-card class="mt-10 mb-10" v-if="data_loaded">
      <v-card-title>
        <div class="d-flex align-center">
          <v-icon x-large>receipt_long</v-icon>
          <h1 class="ms-3">חשבוניות</h1>
        </div>
        <v-spacer></v-spacer>
        <v-btn text exact to="/settings" color="primary">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row class="basic mt-2">
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                background-color="white"
                outlined
                dense
                v-model="item.key"
                label="key"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                background-color="white"
                outlined
                dense
                v-model="item.secret"
                label="secret"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6" md="3" class="py-0">
              <v-select
                outlined
                dense
                :items="invoice_methods"
                item-text="text"
                item-value="value"
                multiple
                v-model="item.methods"
              >
                <template slot="label"> אמצעי תשלום </template>
              </v-select>
            </v-col>

            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                background-color="white"
                readonly
                outlined
                dense
                label="סטטוס"
                :value="item.status ? 'פעיל' : 'לא פעיל'"
              >
                <template slot="append">
                  <v-switch
                    v-model="item.status"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6" md="3" class="py-0">
              <v-text-field
                background-color="white"
                readonly
                outlined
                dense
                label="סטטוס"
                :value="item.send_sms ? 'כן' : 'לא'"
              >
                <template slot="append">
                  <v-switch
                    v-model="item.send_sms"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-chip-group active-class="primary--text" column>
                <v-chip
                  @click="add_key(key.value)"
                  v-for="(key, i) in keys"
                  :key="i"
                >
                  {{ key.text }}
                </v-chip>
              </v-chip-group>

              <v-textarea
                outlined
                dense
                v-model="item.msg"
                label="msg"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pa-8">
        <v-btn
          @click="save()"
          :loading="save_loader"
          :disabled="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-snackbar left v-model="snackbar">
      <p class="white--text">ההגדרות עודכנו בהצלחה</p>
      <template v-slot:action>
        <v-icon color="green" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "invoices",
  data() {
    return {
      data_loaded: false,
      save_loader: false,
      snackbar: false,
      item: {
        key: null,
        secret: null,
        status: false,
        send_sms: false,
        msg: null,
        methods: [],
      },
      invoice_methods: [
        {
          value: "cash",
          text: "cash",
        },
        {
          value: "visa",
          text: "visa",
        },
      ],

      keys: [
        {
          value: "{order_num}",
          text: "מספר הזמנה",
        },
        {
          value: "{customer_name}",
          text: "שם לקוח",
        },
        {
          value: "{customer_phone}",
          text: "מספר טלפון",
        },
        {
          value: "{invoice}",
          text: "חשבונית",
        },
      ],
    };
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "settings/invoice",
            method: "get",
          },
        })
        .then((res) => {
          const data = res.data.data;

          if (data) {
            this.item = Object.assign({}, data);
          }
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save() {
      if (!this.save_loader && this.data_loaded) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "settings/invoice",
              method: "POST",
            },
            data: this.item,
          })
          .then((res) => {
            this.save_loader = false;
            this.snackbar = true;
            console.log(res);
          })
          .catch((err) => {
            this.save_loader = false;
          });
      }
    },
    add_key(key) {
      this.item.msg = `${this.item.msg} ${key}`;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
